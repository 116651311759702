import axios from "axios";

class API {
  // API constructor
  constructor() {
    this.apiURL = process.env.REACT_APP_API_URL;
    this.headers = {};
    this.token = null;
  }

  setToken(token) {
    this.token = token;
  }

  /**
   * CRUD functions
   */

  // Get list of entities from given model
  list(model) {
    return this.request("get", model);
  }

  // Create new entity on given model
  create(model, fields) {
    return this.request("post", model, fields);
  }

  // Read entity from the api for a given model and id
  read(model, id) {
    return this.request("get", model + "/" + id);
  }

  // Update an entity on the given model
  update(model, id, fields) {
    return this.request("put", model + "/" + id, fields);
  }

  // Delete an entity
  delete(model, id) {
    return this.request("delete", model + "/" + id);
  }

  /**
   * HTTP request
   */

  request(method, path, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: method,
        baseURL: this.apiURL,
        url: path,
        data: data ? data : undefined,
        headers: {
          authorization: "Bearer " + this.token,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getClients(params, sort) {
    params = params
      ? "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
      : "";
    params = sort
      ? params +
        "&sort=[" +
        Object.keys(sort)
          .map((key) => '"' + sort[key] + '"')
          .join(",") +
        "]"
      : params;
    return this.list("clients" + params);
  }

  getClient(id) {
    return this.read("clients", id);
  }

  getCampaigns(params, sort) {
    params = params
      ? "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
      : "";
    params = sort
      ? params +
        "&sort=[" +
        Object.keys(sort)
          .map((key) => '"' + sort[key] + '"')
          .join(",") +
        "]"
      : params;
    return this.list("campaigns" + params);
  }

  getCampaign(id) {
    return this.read("campaigns", id);
  }

  getDevices(params, sort) {
    params = params
      ? "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
      : "";
    params = sort
      ? params +
        "&sort=[" +
        Object.keys(sort)
          .map((key) => '"' + sort[key] + '"')
          .join(",") +
        "]"
      : params;
    return this.list("devices" + params);
  }

  getDevice(id) {
    return this.read("devices", id);
  }

  getPlaylists(params, sort) {
    params = params
      ? "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
      : "";
    params = sort
      ? params +
        "&sort=[" +
        Object.keys(sort)
          .map((key) => '"' + sort[key] + '"')
          .join(",") +
        "]"
      : params;
    return this.list("playlists" + params);
  }

  getPlaylist(id) {
    return this.read("playlists", id);
  }

  getCollections(params, sort) {
    params = params
      ? "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
      : "";
    params = sort
      ? params +
        "&sort=[" +
        Object.keys(sort)
          .map((key) => '"' + sort[key] + '"')
          .join(",") +
        "]"
      : params;
    return this.list("collections" + params);
  }

  getCollection(id) {
    return this.read("collections", id);
  }

  getClientCollections(id) {
    return this.list("clients/" + id + "/collections");
  }

  getClientPlaylists(id) {
    return this.list("clients/" + id + "/playlists");
  }

  getMusicUrl(id) {
    return this.list("assets/" + id + "/url");
  }

  getVoiceOverUrl(id) {
    return this.list("voice-overs/" + id + "/url");
  }

  postAsset(file) {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify({}));
    return new Promise((resolve, reject) => {
      axios({
        baseURL: this.apiURL,
        url: "assets",
        data: formData,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  postPlaylistCover(file) {
    var formData = new FormData();
    formData.append("files", file);
    formData.append("data", JSON.stringify({}));
    return new Promise((resolve, reject) => {
      axios({
        baseURL: this.apiURL,
        url: "files/playlist-cover",
        data: formData,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  postCollectionCover(file) {
    var formData = new FormData();
    formData.append("files", file);
    formData.append("data", JSON.stringify({}));
    return new Promise((resolve, reject) => {
      axios({
        baseURL: this.apiURL,
        url: "files/collection-cover",
        data: formData,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getVoiceOvers() {
    return this.list("voice-overs");
  }

  updateVoiceOver(id, data, force) {
    return this.request("put", "voice-overs/" + id + (force ? "?skipValidation=true" : ""), data);
  }

  updateCampaigns(id, data, force) {
    return this.request("put", "campaigns/" + id + (force ? "?skipValidation=true" : ""), data);
  }

  postVoiceOver(data, file, force) {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify(data));
    return new Promise((resolve, reject) => {
      axios({
        baseURL: this.apiURL,
        url: `voice-overs${force ? "?skipValidation=true" : ""}`,
        data: formData,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default new API();
