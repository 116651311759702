import React from "react";
import Select from "react-select";

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: props.settings.selectedItems,
    };

    //
    this.makeCheckbox = this.makeCheckbox.bind(this);
  }

  makeCheckbox(key) {
    let onChange = (e) => {
      e.stopPropagation();
      e.preventDefault();
      let selectedItems = this.state.selectedItems;
      selectedItems[key] = !selectedItems[key];
      this.setState({ selectedItems: selectedItems });
      console.log(this.props);
      if (this.props.settings.onSelect) this.props.settings.onSelect(key);
    };
    return (
      <label className="checkbox" onClick={(e) => onChange(e)}>
        <input
          type="checkbox"
          onChange={() => {}}
          checked={this.state.selectedItems && this.state.selectedItems[key]}
        />
        <span></span>
      </label>
    );
  }

  deleteRow(e) {
    e.preventDefault();
    e.stopPropagation();
    return true;
  }

  render() {
    return (
      <>
        <div className="actions clearfix">
          <div className="filters clearfix">
            {this.props.settings.filters.map((action, i) => {
              if (action.type === "select")
                return (
                  <div key={`field-${i}`} className="search select">
                    <Select
                      value={action.value}
                      onChange={action.onChange}
                      options={action.entities}
                      getOptionLabel={(entity) => entity.name}
                      getOptionValue={(entity) => entity.id}
                    />
                  </div>
                );
              else
                return (
                  <div key={`field-${i}`} className="search icon-bigsearch">
                    <input
                      type="text"
                      placeholder={action.label}
                      onChange={(e) => action.onChange(e.target.value)}
                    />
                  </div>
                );
            })}
          </div>

          <div className="buttons clearfix">
            {this.props.settings.actions.map((action, i) => {
              return (
                <button
                  key={`button-${i}`}
                  className={action.classList}
                  onClick={action.onClick}
                >
                  {action.label}
                </button>
              );
            })}
          </div>
        </div>

        <div className="table-wrapper">
          {this.props.settings.entities && (
            <table>
              <thead>
                <tr>
                  {
                    // this.props.settings.columns.map((column, key) => {
                    // 	return <td key={key}>{column.label}</td>
                    // })

                    // eslint-disable-next-line array-callback-return
                    this.props.settings.columns.map((column, key) => {
                      return (
                        <td className={column.canSort && "sort-icon"} key={key}>
                          {column.label}
                          {column.canSort && (
                            <span
                              onClick={() => column.onSortClick(column)}
                            ></span>
                          )}
                        </td>
                      );
                    })
                  }
                </tr>
              </thead>

              <tbody>
                {this.props.settings.entities.map((entity, rowKey) => {
                  return (
                    <tr
                      key={entity.id}
                      onClick={() =>
                        this.props.settings.onRowClick &&
                        this.props.settings.onRowClick(entity)
                      }
                    >
                      {this.props.settings.columns.map((column, key) => {
                        if (column.action === "delete")
                          return (
                            <td
                              className={
                                column.className ? column.className : ""
                              }
                              key={entity.id + "delete"}
                            >
                              <i
                                className="icon-trash"
                                onClick={(e) =>
                                  this.deleteRow(e, entity.id, rowKey) &&
                                  column.onClick &&
                                  column.onClick(entity.id, rowKey)
                                }
                              ></i>
                            </td>
                          );
                        else
                          return (
                            <td
                              className={
                                column.className ? column.className : ""
                              }
                              key={entity.id + key}
                            >
                              {this.props.settings.multiSelect &&
                                key === 0 &&
                                this.makeCheckbox(rowKey)}{" "}
                              {column.view
                                ? column.view(entity[column.key], entity)
                                : entity[column.key]}
                            </td>
                          );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {(!this.props.settings.entities ||
            !this.props.settings.entities.length) && (
            <div className="empty">There is no content in this list</div>
          )}
        </div>
      </>
    );
  }
}

export default Table;
