import React from 'react';
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import moment from 'moment'
import { debounce } from 'lodash'

class CampaignList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			clients: [],
			filters: {
				name: '',
				client: ''
			},
			clientIndex: [],
			selectedClient: null,
			loading: true,
			campaigns: {
				columns: [{
					label: 'Campaign name',
					key: 'name'
				}, {
					label: 'Client',
					key: 'client',
					view: (field) => {
						console.log(field, this.state.clientIndex)
						if (this.state.clientIndex.indexOf(field) != -1) {
							return this.state.clients[this.state.clientIndex.indexOf(field)].name
						}
						return "???"
					}
				}, {
					label: 'Duration',
					key: 'infinite',
					view: (field, entity) => {
						return field === true ? 'Infinite' : moment(entity.startDate).format('L') + ' - ' + moment(entity.endDate).format('L')
					}
				}, {
					label: 'Status',
					key: 'status',
					view: field => {
						return field === 'on' ? <div className="green">Ongoing</div> : <div className="red">Off</div>
					}
				}, {
					label: 'Devices',
					key: 'devices',
					view: function(field) {
						return field.length
					}
				}, {
					label: 'Priority',
					key: 'priority'
				}, {
					label: 'Last update',
					key: 'updatedAt',
					view: function(field){
						return moment(field).format('l')
					}
				}, {
					action: 'delete',
					onClick: (id, key) => {
						var r = window.confirm("Are you sure you want to delete?");
						if (r === false) return
						API.delete('campaigns', id).then(() => {
							let campaigns = [...this.state.campaigns.entities]
							campaigns.splice(key, 1)
							this.setState({ campaigns: { ...this.state.campaigns, entities: campaigns } })
						})
					}
				}],
				entities: [],
				filters: [{
					label: 'Search for a campaign',
					onChange: debounce(value => {
						if(value !== this.state.filters.name)
							this.setState({ filters: { ...this.state.filters, name: value ? '/' + value + '/i' : '' }}, () => {
								this.loadEntities()
							})
					}, 500)
				}, {
					label: 'Search for a client',
					type: 'select',
					value: null,
					entities: [],
					onChange: value => {
						if(value !== this.state.filters.client)
							this.setState({ filters: { ...this.state.filters, client: value ? value.id : '' }, campaigns: { ...this.state.campaigns, filters: [this.state.campaigns.filters[0], { ...this.state.campaigns.filters[1], value: value }] }}, () => {
								this.loadEntities()
							})
					}
				}],
				actions: [],
				selectedItems: [],
				multiSelect: false,
				onRowClick: (entity) => {
					this.props.history.push('/campaigns/' + entity.id)
				}
			}
		}
	}

	componentDidMount() {
		API.getClients().then(clients => {
			// create index
			clients = [{ name: 'All clients', id: '' }].concat(clients)
			let clientIndex = clients.map(client => client.id)
			this.setState({ clients: clients, clientIndex: clientIndex, campaigns: { ...this.state.campaigns, filters: [this.state.campaigns.filters[0], { ...this.state.campaigns.filters[1], entities: clients, value: clients[0] }] } }, () => {
				this.loadEntities()
			})
		})
	}

	loadEntities() {
		API.getCampaigns(this.state.filters).then(entities => this.setState({ ...this.state, campaigns: {...this.state.campaigns, entities: entities}, loading: false }))
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Campaigns'} />
				<Content>
					<Table settings={this.state.campaigns} />
				</Content>
			</>
	}
}

export default CampaignList;
