/**
 * Helper functions
 */

class Helpers {

	/**
	 * Init helpers
	 */
	constructor(server) {
		this.server = server;
	}

	/**
	 * Check if value is string
	 */
	isString(value){
		return typeof value == 'string';
	}

	/**
	 * Check if value is array
	 */
	isArray(value){
		return Array.isArray(value);
	}
	
	/**
	 * Check if value is object
	 */
	isObject(value){
		return value && value.constructor === Object;
	}
	
	/**
	 * Check if value is boolean
	 */
	isBoolean(value){
		return typeof value == 'boolean';
	}
	
	/**
	 * Check if value is numeric (number)
	 */
	isNumeric(value){
		return typeof value == 'number'
	}
	
	/**
	 * Check if value is numeric and integer
	 */
	isInteger(value){
		return this.isNumeric(value) && Number.isInteger(value);
	}
	
	/**
	 * Check if value is numberic and float
	 */
	isFloat(value){
		return this.isNumeric(value) && !Number.isInteger(value);
	}
	
	/**
	 * Check if value is a function
	 */
	isFunction(value){
		return typeof value == 'function';
	}
	
	/**
	 * Check if value is an email
	 */
	isEmail(value){
		return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
	}
	
	/**
	 * Check if variable is empty (null or undefined)
	 */
	isEmpty(value) {
		return value === undefined || value === null;
	}

	/**
	 * Check if given variable is a timestamp
	 */
	isTimestamp(timestamp) {
		if(this.isNumber(timestamp)) {
			return (new Date(timestamp)).getTime() > 0;
		}
		return false;
	}

	/**
	 * Extend object a values with object b values
	 */
	extend(a, b) {
		for (var key in b)
			a[key] = b[key];
		return a;
	}

	/**
	 * Index entities into id => entity object
	 */
	index(entities, mappingKey) {
		let obj = {}
		if(!mappingKey)
			mappingKey = 'id'

		entities.forEach(entity => {
			obj[entity[mappingKey]] = entity
		})
		return obj
	}

	/**
	 * Bytes to KB / MB / GB / TB...
	 */
	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	formatTime(seconds) {
		const format = val => `0${Math.floor(val)}`.slice(-2)
		const hours = seconds / 3600
		const minutes = (seconds % 3600) / 60

		return [hours, minutes, seconds % 60].map(format).join(':')
	}

	calculateLayoutByDay(events) {
		// Helper function to parse date strings
		const parseDate = (dateStr) => new Date(dateStr);
	
		// Group events by day
		const groupedByDay = {};
		events.forEach((event) => {
			const startDate = parseDate(event.startTime).toISOString().split('T')[0];
			if (!groupedByDay[startDate]) {
				groupedByDay[startDate] = [];
			}
			groupedByDay[startDate].push({
				...event,
				start: parseDate(event.startTime),
				end: parseDate(event.endTime),
			});
		});
	
		const result = [];
	
		// Process each day's events
		Object.entries(groupedByDay).forEach(([date, dayEvents]) => {
			dayEvents.sort((a, b) => a.startTime - b.startTime || a.endTime - b.endTime);
	
			const columns = []; // Track each column's end time
			dayEvents.forEach((event) => {
				let columnAssigned = false;
	
				// Try to assign event to an existing column
				for (let i = 0; i < columns.length; i++) {
					if (event.start >= columns[i]) {
						columns[i] = event.end; // Update the column's end time
						event.column = i;
						columnAssigned = true;
						break;
					}
				}
	
				// If no column could accommodate the event, create a new column
				if (!columnAssigned) {
					event.column = columns.length;
					columns.push(event.end);
				}
			});
	
			// Total columns for the day
			const totalColumns = columns.length;
			const columnWidth = 100 / totalColumns;
	
			// Calculate width and leftOffset for each event
			dayEvents.forEach((event) => {
				event.width = columnWidth;
				event.leftOffset = event.column * columnWidth;
				result.push(event);
			});
		});
	
		return result;
	}
}


export default new Helpers()

